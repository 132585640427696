import React, { useState, useEffect, useRef } from 'react';
import arrow from '../../assets/images/arrow_down.svg';
import ru_flag from '../../assets/images/russian_flag.svg';
import ngn_flag from '../../assets/images/nigerian_flag.svg';
import ghana_flag from '../../assets/images/ghana_flag.png';
import namibia_flag from '../../assets/images/namibia_flag.png';
import south_africa_flag from '../../assets/images/south_africa_flag.png';
import './Transaction.css';
import { AllRates as rates, checkDecimal } from '../../LandingPage/LandingPage';

const currencyOptions = [
  { name: 'NGN', flag: ngn_flag },
  { name: 'RUB', flag: ru_flag },
  { name: 'GHC', flag: ghana_flag },
  { name: 'NAD', flag: namibia_flag },
  { name: 'RAND', flag: south_africa_flag },
];

export const Transaction = ({
  title,
  transactionType,
  setSendCurrency,
  setReceiveCurrency,
  transactionCurrencies,
  setActiveInput,
  sendInput,
  setSendInput,
  receiveInput,
  setReceiveInput,
}) => {
  const [selected, setSelected] = useState(null);

  const [showOptions, setShowOptions] = useState(false);

  const [chosenOption, setChosenOption] = useState({});

  const [focused, setFocused] = useState(false);

  const inputRef = useRef(null);

  const currentRate = rates[transactionCurrencies];

  const handleClick = (index) => {
    setSelected(index);
    setChosenOption(currencyOptions[index]);

    setActiveInput(transactionType);

    if (transactionType === 'send') {
      setSendCurrency(chosenOption.name);
    } else if (transactionType === 'get') {
      setReceiveCurrency(chosenOption.name);
    }
  };

  useEffect(() => {
    if (currencyOptions.length > 0 && transactionType === 'send') {
      setChosenOption(currencyOptions[1]);
    } else if (currencyOptions.length > 0 && transactionType === 'get') {
      setChosenOption(currencyOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (transactionType === 'send') {
      setSendCurrency(chosenOption.name);
    } else if (transactionType === 'get') {
      setReceiveCurrency(chosenOption.name);
    }
  }, [chosenOption.name, transactionType, setReceiveCurrency, setSendCurrency]);

  const handleInputChange = (e) => {
    const calculatedInput = +e.target.value * currentRate;

    let calculatedValue = e.target.value ? calculatedInput : '';

    const value = checkDecimal(calculatedValue);

    if (transactionType === 'send') {
      setSendInput(e.target.value);

      setReceiveInput(value);
    } else if (transactionType === 'get') {
      setReceiveInput(e.target.value);

      setSendInput(value);
    }
  };

  const handleInputFocus = () => {
    setFocused(true);
  };

  const handleInputBlur = () => {
    setFocused(false);
  };

  useEffect(() => {
    if (focused) {
      setActiveInput(transactionType);
    }
  }, [focused, transactionType, setActiveInput]);

  return (
    <div className={focused ? 'transaction-box focused' : 'transaction-box'}>
      <div className='transaction-box-information'>
        <span className='title'>{title}</span>
        <input
          ref={inputRef}
          className='amount'
          value={transactionType === 'send' ? sendInput : receiveInput || ''}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
      </div>
      <div className='transaction-box-currency'>
        <div
          className='dropdown'
          onClick={() => setShowOptions((prev) => !prev)}
        >
          <img src={arrow} alt='down arrow' className='arrow'></img>
          <span className='currency'>
            <img
              src={chosenOption?.flag || ''}
              alt='russian flag'
              className='flag'
            ></img>{' '}
            {chosenOption?.name || ''}
          </span>

          {showOptions && (
            <div className='options'>
              {currencyOptions.map((option, index) => {
                return (
                  <div
                    key={option.name + index}
                    className={`option ${
                      index === selected ? 'highlight' : ''
                    }`}
                    onClick={() => handleClick(index)}
                  >
                    <img src={option.flag} alt='flag' className='flag'></img>{' '}
                    {option.name}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
