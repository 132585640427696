import React, { useState, useEffect } from 'react';
import { Transaction } from '../Components/Transaction';
import { Faq } from '../Components/Faq/Faq';
import './LandingPage.css';
import cross from '../assets/images/cross.svg';
import arrow_down from '../assets/images/arrow_down.svg';
import xendy from '../assets/images/xendy.svg';
import sponsors from '../assets/images/sponsors.svg';
import down_pointer from '../assets/images/down_pointer.svg';
import shield from '../assets/images/shield.svg';
import clock from '../assets/images/clock.svg';
import circle from '../assets/images/big-background-circle.svg';
import equal from '../assets/images/wavy-equal.svg';

export const checkDecimal = (calculatedValue) => {
  if (isNaN(calculatedValue)) return;

  const integer = calculatedValue - Math.floor(calculatedValue) === 0;

  if (!integer) {
    return calculatedValue.toFixed(2);
  } else {
    return calculatedValue;
  }
};

export let AllRates = {};

const COMMISSION = 0.0;

export const LandingPage = ({ languages }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('EN');
  const [openLanguages, setOpenLanguages] = useState(false);
  const [sendCurrency, setSendCurrency] = useState('RUB');
  const [receiveCurrency, setReceiveCurrency] = useState('NGN');

  const [sendInput, setSendInput] = useState('');
  const [receiveInput, setReceiveInput] = useState('');

  const [activeInput, setActiveInput] = useState('send');

  const [rates, setRates] = useState({});

  const transactionCurrencies =
    activeInput === 'send'
      ? `${sendCurrency}-${receiveCurrency}`
      : `${receiveCurrency}-${sendCurrency}`;

  const TOTAL_COMMISSION = () => {
    const commission = (isNaN(sendInput) ? 0 : sendInput) * COMMISSION;
    const integer = commission - Math.floor(commission) === 0;

    if (!integer) {
      return commission.toFixed(2);
    } else {
      return commission;
    }
  };

  const handleLanguageClick = (language) => {
    if (languages.indexOf(language) !== -1) {
      setSelectedLanguage(language);
      setOpenLanguages(!openLanguages);
    }
  };

  useEffect(() => {
    if (activeInput === 'get') {
      const calculatedValue =
        +sendInput * rates[`${sendCurrency}-${receiveCurrency}`];

      const value = checkDecimal(calculatedValue);

      setReceiveInput(value);
    } else if (activeInput === 'send') {
      const calculatedValue = +sendInput * rates[transactionCurrencies];

      const value = checkDecimal(calculatedValue);

      setReceiveInput(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiveCurrency, sendCurrency, rates]);

  const fetchRate = async () => {
    try {
      const data = await fetch(
        'https://xendy-backend.herokuapp.com/api/v1/get_latest_rates'
      );
      const result = await data.json();

      const allRates = {};

      result.forEach((pair) => {
        const from = pair.fiat.toUpperCase();
        const to = pair.currency.toUpperCase();
        allRates[`${from}-${to}`] = pair.rate;
        const hasToFrom = !!allRates[`${to}-${from}`];
        if (!hasToFrom) {
          allRates[`${to}-${from}`] = 1 / pair.rate;
        }
      });

      setRates(allRates);

      AllRates = allRates;


    } catch (e) {}
  };

  useEffect(() => {
    fetchRate();
  }, []);

  useEffect(() => {
    setSendInput(100);
    // setReceiveCurrency();
    // setSendCurrency();
  }, []);

  return (
    <div className='container'>
      <div className='main-page'>
        <div>
          <img
            src={circle}
            alt='rotating circles'
            className='container-background'
          ></img>

          <img
            src={circle}
            alt='rotating circles'
            className='container-background second'
          ></img>
        </div>
        <div className='header-logo-container'>
          <header className='header'>
            <nav className='navigation'>
              <div className='navigation-register-login'>
                <a href="https://app.xendy.io">
                  <span className='navigation-resgister'>
                    <p>Register</p>
                  </span>
                </a>

                <a href="https://app.xendy.io">
                  <span className='navigation-login'>Log in</span>
                </a>
              </div>

              <div
                className='navigation-language'
                onClick={() => setOpenLanguages(!openLanguages)}
              >
                <img
                  src={arrow_down}
                  alt='down arrow'
                  className='navigation-img'
                ></img>
                <p>{selectedLanguage}</p>
                <ul className='navigation-language-list'>
                  {openLanguages &&
                    languages.map((language, index) => {
                      return (
                        <li
                          key={language + index}
                          onClick={() => handleLanguageClick(language)}
                          className={
                            selectedLanguage === language
                              ? 'navigation-language-select'
                              : ''
                          }
                        >
                          {language}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </nav>
          </header>

          <div className='logo-section'>
            <img src={xendy} alt='xendy logo'></img>
          </div>
        </div>

        <section className='about'>
          <div className='about-main'>
            <p>Quick online transfers to Africa</p>
          </div>

          <div className='about-description'>
            <p>
              We help you make fast and secure transfers in the shortest
              possible time
            </p>
          </div>
        </section>

        <section className='transaction'>
          <Transaction
            transactionType='send'
            title='You send'
            sendCurrency={sendCurrency}
            setSendCurrency={setSendCurrency}
            transactionCurrencies={transactionCurrencies}
            sendInput={sendInput}
            setSendInput={setSendInput}
            setActiveInput={setActiveInput}
            receiveInput={receiveInput}
            setReceiveInput={setReceiveInput}
          />

          <img
            src={down_pointer}
            alt='arrow down'
            className='transaction_arrow'
          ></img>

          <Transaction
            transactionType='get'
            title='Recipient will get'
            receiveCurrency={receiveCurrency}
            setReceiveCurrency={setReceiveCurrency}
            transactionCurrencies={transactionCurrencies}
            sendInput={sendInput}
            setSendInput={setSendInput}
            receiveInput={receiveInput}
            setReceiveInput={setReceiveInput}
            setActiveInput={setActiveInput}
          />
        </section>

        <div className='additional-info'>
          <div className='additional-info-sub-container'>
            <div className='additional-info-sub'>
              <span className='additional-info-text'>Commission:</span>
              <span className='additional-info-amount'>
                {TOTAL_COMMISSION()} {sendCurrency}
              </span>
            </div>
            <div className='additional-info-sub rate-sub'>
              <span className='additional-info-text'>Rate:</span>
              <span className='additional-info-amount'>
                {rates[`${sendCurrency}-${receiveCurrency}`] ? (
                  `1 ${sendCurrency} = ${
                    rates[`${sendCurrency}-${receiveCurrency}`]
                  } ${receiveCurrency}`
                ) : (
                  <span className='no-transaction'>
                    The pair is not supported yet
                  </span>
                )}
              </span>
            </div>
          </div>

          <a href='https://app.xendy.io'>
            <div className='start-button'> Get Started</div>
          </a>

          <div className='additional-info-notice'>
            Your money will arrive in 13 mins
          </div>
        </div>
      </div>

      <div className='following-page'>
        <section className='benefits'>
          <div className='benefits-row'>
            <img src={shield} alt='shield'></img>
            <div className='benefits-text'>
              <div className='benefits-main-text'>
                Safety and security guaranteed.
              </div>

              <span className='benefits-added-text'>
                Data is securely protected
              </span>
            </div>
          </div>

          <div className='benefits-row'>
            <img src={clock} alt='clock'></img>
            <div className='benefits-text'>
              <div className='benefits-main-text'>Quick transfer</div>

              <span className='benefits-added-text'>On average, 7 minutes </span>
            </div>
          </div>
        </section>

        <section className='instruction'>
          <div className='instruction-summary'>
            <div className='instruction-heading'>How to send money</div>
            <div className='instruction-heading-detail'>
              No hidden fees. No bad exchange rates. No surprises.
            </div>

            <div className='instruction-time-info'>
              <div className='instruction-time'>
                <img src={equal} alt='approximate'></img> <span>7 minutes</span>
              </div>
              <div className='instruction-text'>
                Average time from start to complete your transfer!
              </div>
            </div>
          </div>

          <ol className='instruction-additional-details'>
            <li>Free registration</li>
            <li>Choose an amount to send.</li>
            <li>Add recipient's bank details.</li>
            <li>Verify your identity.</li>
            <li>Pay for your transfer.</li>
            <li>Done</li>
          </ol>
        </section>

        <section className='faq'>
          <span className='faq-heading'>FAQ</span>

          <div className='faq-body'>
            <Faq
              question='What is Xendy? '
              answer='Xendy is a fin-tech company for global money transfers.'
            />
            <Faq
              question='How can I make a transfer? '
              answer='Simply sign up and start sending money Globally.'
            />
            <Faq
              question='How safe is Xendy? '
              answer='Xendy is trusted by thousands of people in Russia for their global money transfers.'
            />
            <Faq
              question='How do I sign up on Xendy? '
              answer='Simply click on the register button and input the necessary information.'
            />
            <Faq
              question='How long do transfers take? '
              answer='Transfers are usually completed in a few minutes. In rare occasions it may take longer.'
            />
            <Faq
              question='Why is my transaction delayed? '
              answer='Your transactions may be delayed due to some of the following reasons: not clicking the "I have paid button"
              after you have made payment; providing incorrect account details of recipient; not including the Transaction ID when making
              payment; Bank network issues, etc.'
            />
            <Faq
              question='How do I contact you? '
              answer='After signing in you can contact our support by clicking on the contact button on top left corner of the page.'
            />
          </div>
        </section>

        <footer className='footer'>
          <div className='footer-logo-sponsors'>
            <img src={xendy} alt='xendi logo' className='footer-logo'></img>
          </div>
        </footer>
      </div>
    </div>
  );
};
