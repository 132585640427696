import React, { useState } from 'react';
import minus from '../../assets/images/minus.svg';
import plus from '../../assets/images/plus.svg';
import './Faq.css';

export const Faq = ({ question, answer, style = {} }) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = open ? minus : plus;

  return (
    <div
      className='question-container'
      style={style}
      onClick={() => setOpen(!open)}
    >
      <div className='question-heading'>
        <span className='question-title'>{question}</span>

        <img src={toggleOpen} key={toggleOpen} alt='accordion toggle'></img>
      </div>

      <span
        className='question-answer'
        style={open ? { display: 'block' } : { display: 'none' }}
      >
        {answer}
      </span>
    </div>
  );
};
