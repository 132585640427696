import { LandingPage } from './LandingPage';
import { YMInitializer } from 'react-yandex-metrika';
const languages = ['EN'];

function App() {
  return (
    <div className='App'>
      <LandingPage languages={languages} />
      <YMInitializer accounts={[90881492]} options={{
                                              webvisor: true,
                                              clickmap: true,
                                              trackLinks: true,
                                              accurateTrackBounce: true
                                              }} version="2" />
    </div>
  );
}

export default App;
